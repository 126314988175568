import * as React from "react";
import { SumSub } from "@coinlist/frontend-ui-lib"
require("@coinlist/frontend-ui-lib/dist/style.css");

export default function SumSubFlow(props) {
    console.log("joder", props.initialStatus)
    return (
        <SumSub initialStatus={props.initialStatus} entityId={props.entityId} locale={props.locale} accessToken={props.accessToken} email={props.email} />
    );
}
